.select-chat {
    -webkit-box-shadow: 0px 0px 14px 6px rgba(124, 105, 248, 0.43);
    -moz-box-shadow: 0px 0px 14px 6px rgba(124, 105, 248, 0.43);
    box-shadow: 0px 0px 14px 6px rgba(124, 105, 248, 0.43);
}

#customSwitch .custom-switch .custom-control-label::after {
    background-color: #fff;
}

#customSwitch .custom-control-label::before {
    border: #5369f8;
    background-color: #5369f8;
    color: #fff;
}

#customSwitch .custom-control-input:checked~.custom-control-label::before {
    color: #5369f8;
    border-color: #adb5bd;
    background-color: #adb5bd;
}

.libraryList li {
    list-style: none;
    margin-left: -60px;
}

.libraryList .active {
    background-color: #f7f7ff;
    // background-color: aqua;
    padding-left: -30px;
}

.libraryList li a {
    color: #4b4b5a;
    display: block;
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    transition: all 0.4s;
    border-left: 3px solid transparent;
    background-color: transparent;
}

.libraryList .libraryDelete {
    margin: 10px 20px 0 0;
}

.libraryList li a.active {
    // margin-left: -20px;
    margin-right: -20px;
    color: #4b4b5a;
    font-weight: 600;
    font-size: 14px;
}

// .libraryList .active .row:after{
//     content: ' \003E';
//     margin-top: -1px ;
//    float: right;
//     margin-right: 0px;
//     font-size: 16px;
//     color: #4B4B5A;
//     font-weight: 600;
// }
.btnBtm {
    position: absolute;
    right: 110px;
    bottom: 0px;
}

.noDataBtn {
    text-align: center;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
}

.btnBtm button {
    width: 208%;
    margin-left: -20px;
}

.noDataBtn button {
    width: 100%;
}

.email-container .inbox-rightbar {
    margin-left: 235px;
}

.email-container .inbox-leftbar {
    height: 450px;
}

.scrollBar {
    height: 100%;
}

.libraryList {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    /* background-color: aqua;
    */
    margin-left: -21px;
    padding: 0;
    margin-top: -20px;
    margin-right: -21px;
}

.emtpyColumn {
    display: block;
    height: 400px;
    text-align: center;
    line-height: 400px;
}

.libraryList li {
    margin-left: -10px;
}

.icon-dashboard-circular {
    background-color: #5369f8;
    color: #fff;
    text-shadow: 5px 5px rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: 0.4rem;
    align-self: center;
}

.center-col {
    align-self: center;
}

.call-summary-add-agents {
    font-size: 20px;
    line-height: 1.6;
}

div.date-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.date-form div button.dropdown-toggle div {
    color: #4b4b5ac0 !important;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
}

// div.date-form form button {
//     margin-left: 10px;
//     background: #5369F833 !important;
//     border: none !important;
//     color: #5369F8;
//     font-weight: 600;
// }
div.date-form form button svg {
    border: none !important;
    font-size: 0.5rem !important;
    color: #5369f8;
    font-weight: 600;
}

// div.date-form form button:hover {
//     margin-left: 10px;
//     background: #5369f851 !important;
//     border: none !important;
//     font-weight: 600;
// }
// div.date-form form button svg:hover {
//     color: #111;
// }
//
div.react-select__multi-value.filter {
    border-radius: 3px;
    padding: 5px;
    margin: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.filter-value {
    background: rgba(128, 0, 128, 0.089);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    padding: 2px;
}

div.date-form {
    width: 100%;
    border-radius: 20px;
}

div.date-form div button.dropdown-toggle {
    outline: none !important;
    width: 100%;
    background: #fdfdfd !important;
    color: rgba(17, 17, 17, 0.822) !important;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row !important;
    padding-left: 10%;
    padding-right: 10%;
    overflow-x: hidden;
    text-align: center !important;
}

div.date-form div button.dropdown-toggle:focus {
    border: none !important;
}

div.date-form div button.dropdown-toggle div {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0px auto;
}

div.date-form .dropdown {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
}

div.date-form .dropdown form {
    // transform: rotateY(180deg) !important;
    position: absolute;
    left: -100px;
    width: auto !important;
}

div.rem-agents {
    text-align: center;
    margin: 10px;
    font-size: 1.3rem;
    color: #5369f8;
}

div.reset-password div.auth-page-sidebar {
    background: #5268f7 !important;
}

.auth-page-sidebar .overlay {
    background-color: transparent !important;
}

div.reset-password div.auth-page-sidebar div p {
    color: #fff;
}

.modal-header-no-border.modal-header {
    border-bottom: none !important;
}

// ChatSearch styles
div.cs-container {
    background-color: #fdfdfd;
    width: 100% !important;
    margin-bottom: 10px;
    height: 50px;
    border-radius: 3px;
}

div.cs-container .right .buttons {
    display: flex;
}

div.cs-container .right .buttons svg:hover {
    cursor: pointer;
}

div.cs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

div.cs-container .left {
    width: 60%;
}

div.cs-container .left form input {
    width: 100%;
    height: 30px;
    margin-left: 12px;
    font-size: small;
    border: none !important;
}

div.cs-container .left form input:focus {
    outline: none;
}

div.cs-container .left form {
    width: 100%;
    text-align: center;
}

div.cs-container .right {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

span.chat-highlight {
    background: orange;
}

span.chat-highlight.current {
    background: yellow !important;
}

div.cs-container .right .count {
    margin: 0px 5px 0px 0px;
    border-right: 1px solid grey;
    padding-right: 10px;
}

div.chat-conversation {
    padding-top: 20px;
}

div.chat-scroll {
    height: 250px;
    padding-bottom: 10px;
}

// calendar
div.date-form .dropdown form.show {
    // transform: rotateY(180deg) !important;
    position: absolute;
    left: -50px;
    width: auto !important;
    display: flex;
    flex-direction: column;
}

.rdrDay .rdrDayHovered {
    border: 1px solid #5268f7 !important;
}

.rdrStartEdge {
    background-color: #5268f7 !important;
}

.rdrInRange {
    color: #adb5bd !important;
    background-color: #5268f783 !important;
}

.rdrDayNumber {
    color: #4b4b5a !important;
}

.rdrEndEdge {
    background-color: #5268f7 !important;
}

@media(max-width:1500px) {
    div.date-form .dropdown form.show {
        left: -60px;
    }
}

@media(max-width:1350px) {
    div.date-form .dropdown form.show {
        left: -100px;
    }
}

@media(max-width:1200px) {
    div.date-form .dropdown form.show {
        left: -140px !important;
    }
}

@media(max-width:1000px) {
    div.date-form .dropdown form.show {
        left: 0px !important;
    }
}

// call summary calendar
.page-title div.date-form .dropdown {
    width: 300px !important;
    // position: relative;
    position: relative;
    // right: -500px !important;
    display: block !important;
}

.moment-themes:hover {
    background: #DDE1FE;
}

.moment-themes {
    background: #F3F4F7;
}

.moment-themes-active {
    background: #DDE1FE;
    border: 2px solid #5369F9;
}

.page-title div.date-form {
    justify-content: flex-end;
}

//upload div
// .call-upload-container .row {
//     padding-top: 0px;
//     margin-bottom: 0px !important;
//     overflow: visible;
//     height: auto !important;
//     transform: scale(0.95);
//     padding-bottom: 20px;
// }
// @media(max-height:780px) {
//     .call-upload-container .row {
//         padding-top: 0px !important;
//         transform: scale(0.9);
//         padding-bottom: 20px;
//     }
// }
.call-upload-container {
    transform: scale(0.95);
    // height: 70vh !important;
}

div.logs-container {
    background-color: #fdfdfd;
    padding: 20px;
    border-radius: 4px;
}

div.logs-container {
    margin-top: 20px;
}

// div.heading {
//     margin: 20px;
// }
@media(max-height:780px) {
    .call-upload-container {
        transform: scale(0.84);
        // height: 70vh !important;
    }
}

div.AGENT {
    background: rgba(255, 0, 0, 0.209) !important;
    color: #5268f7 !important;
}

div.THEME {
    background: #ffbf003f !important;
    color: #5268f7 !important;
}

div.MOMENT {
    background: rgba(0, 255, 225, 0.315)!important;
    color: #5268f7 !important;
}

.left-timeline .events .event-list::before {
    z-index: 1 !important;
}

.change-pass-btn {
    font-size: 1rem;
    padding: 5px !important;
    background: #5268f7 !important;
    border: none !important;
    display: block;
}

.uil-clipboard {
    color: #5268f7
}

.input-group-prepend:hover {
    cursor: pointer;
}

.copied-text {
    margin-left: 10px;
    color: #5268f7;
}

.alert-danger {
    color: #ff5c75;
    background-color: #fff;
}

.alert-success {
    color: #43d39e;
    background-color: #fff;
}

.select-team {
    background-color: #fff !important;
}

.download-report {
    position: relative;
    top: 5px;
    left: -10px;
}

button.date-dropdown {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: transparent !important;
}

div.report-form.report .dropdown {
    width: 70% !important;
}

ul.report-dates {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px !important;
    padding: 0px !important;
}

ul.report-dates li {
    margin: 1px;
    color: #5268f7;
    margin: 3px;
    padding: 2px;
    transition: 0.1s ease;
}

ul.report-dates li:hover {
    margin: 1px;
    cursor: pointer;
    color: #5268f7;
    border-bottom: 2px solid #5268f752;
}

.date-form.report {
    width: 100% !important;
    background-color: #f1f1f1;
}

// .dropdown-menu.dropdown-lg.p-3.show.report {
//     position: absolute;
// }
.dropdown-menu.dropdown-menu-right.report {
    width: 600px !important;
    padding: 10px;
}

div.date-form div button.dropdown-toggle div.report {
    margin: 0px !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
}

.download-report {
    position: relative;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    top: 9px;
    left: 10px !important;
}

.modal-delete .modal-header h5.modal-title {
    text-align: left !important;
    font-size: 10px !important;
}

.modal-delete .modal-header h5 span {}

.qa-table div table tbody tr td:last-child {
    // color: red !important;
    display: flex;
    align-items: center;
}

.qa-table div table tbody tr td:last-child div i {
    padding-left: 15px;
}

.team-list-table table tbody tr td {
    width: 70px !important;
}

.refresh-spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.refresh-btn {
    font-size: 16px !important;
    text-transform: uppercase !important;
}

.uil.moment-mail {
    font-size: 1.5rem;
    color: rgba(128, 128, 128, 0.486);
    padding: 0px 10px !important;
}

.uil.moment-mail.active {
    font-size: 1.5rem;
    padding: 0px 10px !important;
    color: #43D39E;
}

.badge-edit {
    display: flex;
}

.badge-edit i {
    opacity: 0;
    transition: 0.2s ease;
}

.badge-edit:hover i {
    opacity: 1;
}

.react-select.text-danger {
    border: 2px solid red !important;
    border-radius: 5px !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.button-menu-mobile.disable-btn {
    display: block !important;
}

.thumbnail-image:hover {
    cursor: pointer;
}

.video-play {
    position: absolute;
    left: 46%;
    top: 46%;
    font-size: 2rem;
    color: gray;
    // z-index: -1;
}