// 
// custom.scss
//
.page-title {
    padding: 18px 0;
    .breadcrumb {
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
    }

    h4 {
        font-size: 26px!important;
    }
}

// Custom table components (Custom)
.table {
    table-layout:auto !important;
    thead {
            th {
                //font-size: 20px !important;
            }
    }
    tbody{
        td{
            //font-size: 20px !important;
        }
    }
}
#search-bar-0{
    width:220px !important;
}
.create-btn{
    font-size:16px!important;
    text-transform:uppercase!important;
    background-color:#5369f8!important;
    border-color:#5369f8!important;
}
.create-btn:active:focus{
    box-shadow:0 0 0 0.15rem #5369f8!important;
}
.modal-title{
    font-size:26px !important;
}
.form-control{
    font-size:16px !important;
}
.cancel-btn{
    background-color:#fff !important;
    color:#1e2139!important;
    border-color:#1e2139!important;
}
.font-size-14{
    font-size:14px !important;
}
.actionTbl{
    td{
        border-top:0px!important;
        padding-top:0px;
    }
}
.error{
    color:#ff5c75 !important;
}

.aliceblue{
    background-color:aliceblue !important;
}
.font-weight-500{
    font-weight:500!important;
}
.create-btn:hover {
    color: #fff;
    background-color: #2e49f7!important;
    border-color: #223ef6!important;
}
.font-weight-600{
    font-weight:600 !important;
}
.font-size-18{
    font-size:18px !important;
}
.dashboardTitle{
    color:#4B4B5A !important;
}
// .media-body{
//     span{
//         font-size:16px !important;
//     }
// }
.tab-pane{
    .card-body{
        padding-top:0px!important;
    }
}
.call-badges{
    height: 30px;
    min-width: 100px !important;
    padding-top: 9px;
}
.font-size-22{
    font-size:22px !important;
}
.uil{
    cursor:pointer !important;
}

#select-filter-column-momentGroupName{
    position: absolute;
    top: 20px;
    width: 17.6%;
    left: 2%;
}
.select-filter.placeholder-selected{
    color: #6c757d !important;
    font-style:inherit !important;
}
#select-filter-column-caller{
    position: absolute;
    top: 1%;
    width: 31.6%;
    left: -34%;
}
.callType-filter{
    position: absolute;
    top: 15px;
    width: 14.6%;
    left: 17.7%;
}
#select-filter-column-words{
    position: absolute;
    top: 0px;
    width: 14.6%;
    left: 34.6%;
}
.react-bootstrap-table>table>thead>tr>th .filter-label{
    margin:0!important;
}
@media screen and (min-device-width : 1200px) and (max-device-width : 1299px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 25%;
        left:2%;
    }
}
@media screen and (min-device-width : 1300px) and (max-device-width : 1399px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 23%;
        left:2%;
    }
}
@media screen and (min-device-width : 1400px) and (max-device-width : 1499px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 23%;
        left:2%;
    }
}
@media screen and (min-device-width : 1500px) and (max-device-width : 1599px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        left: 2%;
    }
}
@media screen and (min-device-width : 1600px) and (max-device-width : 1699px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 20%;
        left:2%;
    }
}
@media screen and (min-device-width : 1700px) and (max-device-width : 1799px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 18%;
        left:2%;
        }
}
@media screen and (min-device-width : 1800px) and (max-device-width : 1899px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 17%;
        left:2%;
    }
}
@media screen and (min-device-width : 1900px) and (max-device-width : 1999px)
{
    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 17.6%;
        // left: 16%;
        left:2%;
    }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .agent-filter{
        position: absolute;
        top: 15px;
        width: 20.6%;
        left: 1.7%;
    }
    .callType-filter{
        position: absolute;
        top: 15px;
        width: 25.6%;
        left: 24.7%;
    }

    #select-filter-column-momentGroupName{
        position: absolute;
        top: 20px;
        width: 33.6%;
        // left: 27%;
        left:4%;
    }

    #search-bar-0 {
        width: 142px !important;
    }
    h5.text-color{
        display:none !important;
    }

    }

    .preloader {
        position: absolute;
        top: 350% !important;
        left: 0;
        right: 0;
        bottom: 0;
        /* background-color: rgba(255, 255, 255, 0.75); */
        z-index: 9999;
    }
h5.text-color{
    color: #ff5c75 !important;
    font-weight: 400 !important;
}
  .cursor-pointer{
      cursor:pointer !important;
  }
  #summaryTbl th:nth-child(4) {
    //   padding-right:5% !important;
    }
//   #summaryTbl td:nth-child(1) {text-align:center !important;}
  #summaryTbl td:nth-child(2) {text-align:right !important;}
  #summaryTbl td:nth-child(3) {text-align:right !important;}
  #summaryTbl td:nth-child(4) {text-align:right !important;}
  .progress{cursor:pointer !important;}
  .soft-success{color:#43d39e !important}
  #analysisTbl td:nth-child(5) > div,#analysisTbl td:nth-child(3) > div,#analysisTbl td:nth-child(6) > div,  #analysisTbl td:nth-child(2), #analysisTbl td:nth-child(4) {text-align:right !important;}
  .success-toast{color:green!important;}
  .Toastify__progress-bar--default{background:#5369f8 !important;}
  .error-toast{color:#ff5c75 !important;}
  .Toastify__progress-bar{transform-origin:right !important;}
  .font-size-13{font-size:13px !important;}
  #analysisTbl td:nth-child(5) > svg{width:20px !important;height:20px !important;}

 ::placeholder {color:#ccc!important;opacity: 1!important; /* Firefox */} 
 :-ms-input-placeholder { /* Internet Explorer 10-11 */color:#ccc!important;}
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #ccc!important;
  }
  .placeholder {
    color:#ccc!important;
  }

  .form-title-slider {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: white;
  }

  .form-title-background {
      
    background: #2e49f7;
    // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    position: sticky;
    top: 0px;
    z-index: 999;
  }

  .form-title-col{
      padding-left: 4em !important;
  }

  .slider-panel{
      overflow-x: hidden !important;
      height: 100% !important;

    }

    .slider-panel-hard {
        height: 100% !important;
    }

    .right-border {
        border-right:  2px solid #f6f6f7!important;
    }

    .top-border {
        border-top:  2px solid #f6f6f7!important;
    }

    .dashboard-summary-flash-card-text {
        font-size: 20px;
        align-self: center;
        text-align: center;
    }

    .dashboard-since-timestamp {
        text-align: center;
    font-weight: 600;
    word-break: break-word;
    font-size: .825em;
    }

    .call-analysis-list-nav {
        border-bottom-color : white
    }


.subtitle-heading{
    color: #999999;
}